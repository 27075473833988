import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const FAQ = () => {
  const faqs = [
    {
      question: "Quel est l'engagement minimum ?",
      answer: "Nos abonnements sont avec un engagement d'un an minimum. En contrepartie de cet engagement, vous bénéficiez des tarifs promotionnels à vie, ce qui représente une économie significative sur le long terme."
    },
    {
      question: "Les tarifs promotionnels sont-ils vraiment garantis à vie ?",
      answer: "Oui, absolument ! En vous engageant maintenant, vous bénéficiez des tarifs promotionnels actuels qui resteront inchangés tant que vous restez client, même après la période d'engagement initiale d'un an."
    },
    {
      question: "Combien de temps faut-il pour mettre en place l'application ?",
      answer: "La mise en place prend généralement entre 1 et 2 semaines, incluant la personnalisation de votre interface et la formation de votre équipe."
    },
    {
      question: "Proposez-vous une formation pour utiliser l'application ?",
      answer: "Oui, nous proposons une formation complète incluse dans les frais initiaux. De plus, vous avez accès à des tutoriels vidéo et une documentation détaillée."
    },
    {
      question: "Que comprennent les frais de mise en service ?",
      answer: "Les frais initiaux de 300€ couvrent la personnalisation complète de votre application (charte graphique, menu, configuration), sa publication sur les stores, ainsi que la formation de votre équipe. C'est un investissement unique qui vous garantit un démarrage optimal."
    },
    {
      question: "Comment s'effectue le paiement ?",
      answer: "Le paiement des frais initiaux s'effectue en une fois avant le démarrage du projet. L'abonnement mensuel est ensuite prélevé automatiquement, avec un engagement de 12 mois minimum pour bénéficier des tarifs promotionnels à vie."
    },
    {
      question: "Que se passe-t-il après la période d'engagement d'un an ?",
      answer: "Après la période d'engagement d'un an, votre abonnement continue automatiquement aux mêmes tarifs promotionnels. Vous pouvez alors résilier à tout moment avec un préavis d'un mois, tout en conservant vos tarifs préférentiels si vous décidez de revenir plus tard."
    }
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleContactClick = (e) => {
    e.preventDefault();
    window.location.href = "mailto:contact@garnii.fr?subject=Demande d'information Garnii&body=Bonjour,%0D%0A%0D%0AJe souhaiterais avoir plus d'informations concernant vos services.%0D%0A%0D%0ACordialement,";
  };

  return (
    <section id="faq" className="py-20 bg-white">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-semibold text-center mb-4">Questions fréquentes</h2>
        <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
          Tout ce que vous devez savoir sur notre service et nos conditions d'abonnement
        </p>
        <div className="max-w-3xl mx-auto space-y-4">
          {faqs.map((faq, index) => (
            <div 
              key={index}
              className="bg-white rounded-lg shadow-md"
            >
              <button
                className="w-full p-6 text-left flex justify-between items-center hover:bg-gray-50 transition-colors duration-200 rounded-lg"
                onClick={() => setActiveIndex(activeIndex === index ? null : index)}
                aria-expanded={activeIndex === index}
              >
                <span className="font-medium text-lg pr-8">{faq.question}</span>
                {activeIndex === index ? 
                  <ChevronUp className="w-5 h-5 text-emerald-700 flex-shrink-0" /> : 
                  <ChevronDown className="w-5 h-5 text-emerald-700 flex-shrink-0" />
                }
              </button>
              <AnimatePresence>
                {activeIndex === index && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <div className="px-6 pb-6 text-gray-600">
                      {faq.answer}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>

        <div className="mt-12 text-center">
          <p className="text-gray-600">
            Une autre question ? N'hésitez pas à nous contacter
          </p>
          <a 
            onClick={handleContactClick}
            href="mailto:contact@garnii.fr"
            className="inline-block mt-4 px-6 py-3 bg-emerald-700 text-white rounded-md hover:bg-emerald-800 transition-colors duration-300"
          >
            Contactez-nous
          </a>
        </div>
      </div>
    </section>
  );
};

export default FAQ;