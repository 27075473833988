import React from 'react';
import { motion } from 'framer-motion';
import { Clock, Star, TrendingUp } from 'lucide-react';

const Hero = () => {
  /*const stats = [
    { value: "+27%", label: "de CA en moyenne", icon: <ChartArea className="w-5 h-5 text-emerald-700" /> },
    { value: "x3.5", label: "taux de fidélisation", icon: <Users className="w-5 h-5 text-emerald-700" /> },
    { value: "89%", label: "de taux d'engagement", icon: <Bell className="w-5 h-5 text-emerald-700" /> },
    { value: "4.9/5", label: "satisfaction client", icon: <Star className="w-5 h-5 text-emerald-700" /> }
  ];*/
  const handleStartNow = (e) => {
    e.preventDefault();
    window.location.href = "mailto:contact@garnii.fr?subject=Démarrage avec Garnii&body=Bonjour,%0D%0A%0D%0AJe voudrais en savoir plus sur votre solution Garnii.%0D%0A%0D%0AMerci de me recontacter au plus vite.%0D%0A%0D%0ACordialement,";
  };

  const handleDemoRequest = (e) => {
    e.preventDefault();
    window.location.href = "mailto:contact@garnii.fr?subject=Demande de démo Garnii&body=Bonjour,%0D%0A%0D%0AJe souhaiterais voir une démonstration de Garnii.%0D%0A%0D%0AMerci de me proposer un créneau pour une présentation.%0D%0A%0D%0ACordialement,";
  };

  return (
    <section className="relative flex min-h-screen w-full flex-col overflow-hidden bg-gradient-to-br from-white to-gray-100 p-4 md:p-[5%]">
      <div className="flex h-full min-h-screen w-full flex-col place-content-center items-center text-center">

        <motion.h1
          className="mb-6 text-4xl md:text-5xl lg:text-7xl font-extrabold leading-tight tracking-tight text-gray-900"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          Votre restaurant <br />
          <span className="bg-gradient-to-r from-emerald-700 to-emerald-500 bg-clip-text text-transparent">dans leur poche</span>
        </motion.h1>

        <motion.p className="mb-8 max-w-2xl text-lg md:text-xl lg:text-2xl text-gray-600">
          Plus de temps en cuisine, moins en caisse : laissez l'app gérer les commandes pendant que vous vous concentrez sur l'essentiel
        </motion.p>

        {/* Statistiques */}

        {/* 
        <motion.div
          className="mb-12 grid grid-cols-2 gap-6 md:grid-cols-4 lg:gap-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          {stats.map((stat, index) => (
            <div key={index} className="text-center">
              <div className="mb-2 flex justify-center">{stat.icon}</div>
              <div className="text-2xl font-bold text-gray-900">{stat.value}</div>
              <div className="text-sm text-gray-600">{stat.label}</div>
            </div>
          ))}
        </motion.div>
        */}

        <motion.div
          className="mb-12 grid grid-cols-1 gap-4 text-left sm:grid-cols-2 lg:grid-cols-3"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <div className="flex items-start space-x-3 rounded-lg bg-white p-4 shadow-sm">
            <div className="rounded-full bg-emerald-100 p-2">
              <Clock className="h-5 w-5 text-emerald-700" />
            </div>
            <div>
              <h3 className="font-medium">Gagnez du temps</h3>
              <p className="text-sm text-gray-600">Plus de temps pour préparer vos recettes</p>
            </div>
          </div>

          <div className="flex items-start space-x-3 rounded-lg bg-white p-4 shadow-sm">
            <div className="rounded-full bg-emerald-100 p-2">
              <TrendingUp className="h-5 w-5 text-emerald-700" />
            </div>
            <div>
              <h3 className="font-medium">Augmentez vos ventes</h3>
              <p className="text-sm text-gray-600">Application intuitive et efficace</p>
            </div>
          </div>

          <div className="flex items-start space-x-3 rounded-lg bg-white p-4 shadow-sm">
            <div className="rounded-full bg-emerald-100 p-2">
              <Star className="h-5 w-5 text-emerald-700" />
            </div>
            <div>
              <h3 className="font-medium">Fidélisez</h3>
              <p className="text-sm text-gray-600">Abonnements et promotions ciblés</p>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
          className="flex flex-col sm:flex-row gap-4 sm:gap-4"
        >
          <a
            onClick={handleStartNow}
            href="mailto:contact@garnii.fr"
            className="px-6 py-3 rounded-md bg-emerald-700 text-base md:text-lg font-semibold text-white shadow-lg transition-all duration-300 ease-in-out hover:bg-emerald-800 hover:shadow-xl"
          >
            Démarrer maintenant
          </a>
          <a
            onClick={handleDemoRequest}
            href="mailto:contact@garnii.fr"
            className="px-6 py-3 rounded-md bg-white text-base md:text-lg font-semibold text-emerald-700 shadow-lg transition-all duration-300 ease-in-out hover:bg-emerald-50"
          >
            Voir une démo
          </a>
        </motion.div>
      </div>
      {/* Bannière clients */}
      {/* 
      <motion.div
        className="absolute bottom-0 left-0 w-full bg-white py-4 shadow-md"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 1.2 }}
      >
        <div className="container mx-auto px-4">
          <p className="text-center text-sm text-gray-600">
            Déjà adopté par <span className="font-semibold text-emerald-700">plus de 300 restaurants</span> à travers la France
          </p>
        </div>
      </motion.div>
      */}
    </section>
  );
};

export default Hero;