import React from 'react';
import { motion } from 'framer-motion';
import { Smartphone, Settings, UserCheck, ChartBar, TrendingUp } from 'lucide-react';

const HowItWorks = () => {
  const steps = [
    {
      icon: <Smartphone className="w-12 h-12 text-emerald-700" />,
      title: "1. Personnalisation",
      description: "Nous personnalisons l'application selon votre identité visuelle et vos besoins spécifiques"
    },
    {
      icon: <Settings className="w-12 h-12 text-emerald-700" />,
      title: "2. Configuration",
      description: "Nous paramétrons votre menu, vos prix et vos options de paiement"
    },
    {
      icon: <UserCheck className="w-12 h-12 text-emerald-700" />,
      title: "3. Formation",
      description: "Nous formons votre équipe à l'utilisation de l'application"
    },
    {
      icon: <ChartBar className="w-12 h-12 text-emerald-700" />,
      title: "4. Lancement",
      description: "Vous êtes prêt à booster votre activité avec Garnii"
    },
    {
      icon: <TrendingUp className="w-12 h-12 text-emerald-700" />,
      title: "5. Croissance",
      description: "Boostez vos ventes grâce à notre système de fidélité premium et nos analyses prédictives"
    }
  ];

  return (
    <section id="how-it-works" className="py-20 bg-gray-50">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-semibold text-center mb-12">Comment ça marche ?</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              className="flex flex-col items-center text-center relative"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
            >
              <div className="mb-6 p-4 bg-emerald-50 rounded-full">
                {step.icon}
              </div>
              <h3 className="text-xl font-semibold mb-4">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
              {index < steps.length - 1 && (
                <div className="hidden lg:block absolute -right-4 top-12">
                  <motion.div
                    className="w-8 h-0.5 bg-emerald-100"
                    initial={{ width: 0 }}
                    whileInView={{ width: 32 }}
                    transition={{ duration: 0.5, delay: index * 0.1 + 0.3 }}
                    viewport={{ once: true }}
                  />
                </div>
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;