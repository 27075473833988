import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Check, TrendingUp } from 'lucide-react';

const Pricing = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const handleStartNow = (e) => {
    e.preventDefault();
    window.location.href = "mailto:contact@garnii.fr?subject=Démarrage avec Garnii&body=Bonjour,%0D%0A%0D%0AJe voudrais en savoir plus sur votre solution Garnii.%0D%0A%0D%0AMerci de me recontacter au plus vite.%0D%0A%0D%0ACordialement,";
  };

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <section id="pricing" className="bg-white py-20" ref={ref}>
      <div className="container mx-auto px-6">
        <motion.h2 
          className="text-4xl font-bold text-center mb-4"
          variants={{
            hidden: { opacity: 0, y: -20 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={controls}
          transition={{ duration: 0.5 }}
        >
          Une offre complète pour votre activité
        </motion.h2>
        <motion.p 
          className="text-center text-gray-600 max-w-2xl mx-auto mb-8"
          variants={{
            hidden: { opacity: 0, y: -20 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={controls}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Tout ce dont vous avez besoin pour développer votre food truck
        </motion.p>

        <motion.div 
          className="bg-emerald-700 text-white text-center py-3 px-6 rounded-xl mb-12 max-w-xl mx-auto"
          variants={{
            hidden: { scale: 0.8, opacity: 0 },
            visible: { scale: 1, opacity: 1 },
          }}
          initial="hidden"
          animate={controls}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <div className="text-xl font-bold">🚀 Offre de lancement : Tarif promotionnel garanti à vie</div>
        </motion.div>

        <div className="flex justify-center mb-8">
          <motion.div 
            className="bg-white p-8 rounded-xl shadow-lg max-w-2xl w-full border border-gray-100"
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={controls}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <div className="text-center mb-8">
              <h3 className="text-2xl font-bold mb-6">Solution clef en main</h3>
              <div className="mb-2">
                <span className="text-5xl font-bold text-emerald-700">39€</span>
                <span className="text-xl text-gray-600"> /mois</span>
              </div>
              <div className="flex items-center justify-center gap-3 text-gray-500">
                <span className="text-lg line-through">99€ /mois</span>
                <span className="bg-emerald-100 text-emerald-700 text-sm font-medium px-2 py-1 rounded">-60%</span>
              </div>
              <p className="text-sm text-gray-600 mt-2">Engagement 12 mois - Frais de mise en place 500€</p>
            </div>

            <div className="space-y-4 mb-8">
              <FeatureItem text="Application mobile Android & iOS personnalisée pour vos clients" />
              <FeatureItem text="Dashboard de gestion complet (menus, stocks, commandes)" />
              <FeatureItem text="Application mobile de gestion pour votre équipe" />
              <FeatureItem text="Click & Collect et paiement en ligne" />
              <FeatureItem text="Notifications push pour fidéliser vos clients" />
              <FeatureItem text="Programme de fidélité intégré" />
              <FeatureItem text="Gestion des codes promos et remises personnalisées" />
              <FeatureItem text="Support technique 7j/7" />
              <FeatureItem text="Analyses et statistiques détaillées" />
            </div>

            <button 
              onClick={handleStartNow}
              href="mailto:contact@garnii.fr"
              className="w-full py-4 rounded-xl font-semibold transition duration-300 bg-emerald-700 text-white hover:bg-emerald-800"
            >
              Démarrer maintenant
            </button>
            
            <div className="text-center mt-6 space-y-2">
              <p className="text-sm text-gray-600">
                Les frais de mise en place incluent la personnalisation graphique, 
                la mise en ligne sur les stores et la formation
              </p>
              <p className="text-sm font-medium text-emerald-700">
                Satisfait ou remboursé pendant 30 jours
              </p>
            </div>
          </motion.div>
        </div>

        <motion.div
          className="max-w-2xl mx-auto text-center"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={controls}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <div className="flex items-center justify-center gap-2 text-emerald-700 mb-3">
            <TrendingUp className="w-5 h-5" />
            <h3 className="text-lg font-semibold">Impact moyen constaté</h3>
          </div>
          <div className="grid grid-cols-3 gap-8 text-gray-600">
            <div>
              <div className="font-bold text-emerald-700 text-lg">+15-30%</div>
              <div className="text-sm">Chiffre d'affaires</div>
            </div>
            <div>
              <div className="font-bold text-emerald-700 text-lg">+40-60%</div>
              <div className="text-sm">Fidélisation client</div>
            </div>
            <div>
              <div className="font-bold text-emerald-700 text-lg">+20-35%</div>
              <div className="text-sm">Panier moyen</div>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

const FeatureItem = ({ text, primary }) => (
  <div className={`flex items-center space-x-3 ${primary ? 'font-medium' : ''}`}>
    <Check className="w-5 h-5 text-emerald-700 flex-shrink-0" />
    <span className="text-gray-800">{text}</span>
  </div>
);

export default Pricing;