import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const AnalyticsTracker = () => {
  useEffect(() => {
    // Initialiser Google Analytics
    ReactGA.initialize('G-6YYXG4VV4F'); 

    // Tracker les vues de pages
    const trackPageView = () => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    };

    // Tracking des sources de trafic
    const trackSource = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const utmSource = urlParams.get('utm_source');
        const utmMedium = urlParams.get('utm_medium');
        const utmCampaign = urlParams.get('utm_campaign');
        const referrer = document.referrer;

        // Envoyer les données de source
        ReactGA.event({
            category: 'Traffic Source',
            action: 'Visit',
            label: utmSource || 'direct',
            value: 1,
            dimensions: {
            source: utmSource || referrer || 'direct',
            medium: utmMedium || 'none',
            campaign: utmCampaign || 'none',
            fullReferrer: referrer || 'none'
            }
        });
        };

    // Tracker les événements importants
    const trackEvents = () => {
      // Suivi des clics sur le bouton d'essai gratuit
      document.querySelectorAll('button').forEach(button => {
        if (button.textContent.includes('Essai Gratuit')) {
          button.addEventListener('click', () => {
            ReactGA.event({
              category: 'Conversion',
              action: 'Click',
              label: 'Free Trial Button'
            });
          });
        }
      });

      // Suivi des vues de sections importantes
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            ReactGA.event({
              category: 'Section View',
              action: 'View',
              label: entry.target.id
            });
          }
        });
      });

      ['faq', 'how-it-works','numbers', 'features', 'pricing', 'testimonials'].forEach(sectionId => {
        const section = document.getElementById(sectionId);
        if (section) observer.observe(section);
      });
    };

    const trackSocialInteractions = () => {
        document.querySelectorAll('a').forEach(link => {
          if (link.href.includes('facebook.com') || 
              link.href.includes('twitter.com') || 
              link.href.includes('linkedin.com')) {
            link.addEventListener('click', () => {
              ReactGA.event({
                category: 'Social',
                action: 'Click',
                label: link.href
              });
            });
          }
        });
      };

      
    trackSource();
    trackSocialInteractions();
    trackPageView();
    trackEvents();

    return () => {
      // Nettoyage des event listeners si nécessaire
    };
  }, []);

  return null;
};

export default AnalyticsTracker;