
const Testimonials = () => (
    <section id="testimonials" className="py-20 bg-gray-50">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-semibold text-center mb-12">Ce que disent nos utilisateurs</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <Testimonial 
            quote="Garnii a amélioré la gestion de mon food truck. Je gagne un temps précieux sur l'administration et je peux me concentrer sur la cuisine."
            author="Thomas M., Propriétaire de 'O'Pizzaï'OL'"
          />
        </div>
      </div>
    </section>
  );
  
  const Testimonial = ({ quote, author }) => (
    <div className="p-6 rounded-lg shadow-md">
      <p className="mb-4">"{quote}"</p>
      <p className="font-semibold">{author}</p>
    </div>
  );

  export default Testimonials;