import React from 'react';
import { Link } from 'react-scroll';
import { Mail, Phone, MapPin } from 'lucide-react';

const Footer = () => (
  <footer className="bg-gray-900 text-gray-300">
    {/* Section principale */}
    <div className="container mx-auto px-6 py-12">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
        {/* À propos */}
        <div>
          <h3 className="text-white font-bold text-lg mb-4">À propos de Garnii</h3>
          <p className="mb-4">
            Solution innovante de click & collect et fidélisation pour les professionnels de la restauration.
          </p>
        </div>

        {/* Liens rapides */}
        <div>
          <h3 className="text-white font-bold text-lg mb-4">Liens rapides</h3>
          <ul className="space-y-2">
            <li>
              <Link to="features" smooth={true} duration={500} className="hover:text-emerald-700 transition-colors cursor-pointer">
                Fonctionnalités
              </Link>
            </li>
            <li>
              <Link to="how-it-works" smooth={true} duration={500} className="hover:text-emerald-700 transition-colors cursor-pointer">
                Comment ça marche ?
              </Link>
            </li>
            <li>
              <Link to="pricing" smooth={true} duration={500} className="hover:text-emerald-700 transition-colors cursor-pointer">
                Tarifs
              </Link>
            </li>
            <li>
              <Link to="faq" smooth={true} duration={500} className="hover:text-emerald-700 transition-colors cursor-pointer">
                FAQ
              </Link>
            </li>
          </ul>
        </div>

        {/* Ressources */}
        <div>
          
        </div>

        {/* Contact */}
        <div>
          <h3 className="text-white font-bold text-lg mb-4">Contact</h3>
          <ul className="space-y-2">
            <li className="flex items-center space-x-2">
              <Mail size={16} />
              <a href="mailto:contact@garnii.fr" className="hover:text-emerald-700 transition-colors">
                contact@garnii.fr
              </a>
            </li>
            <li className="flex items-center space-x-2">
              <Phone size={16} />
              <span>+33 6 20 41 94 77</span>
            </li>
            <li className="flex items-center space-x-2">
              <MapPin size={16} />
              <span>Grenoble, France</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;