import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import HowItWorks from './components/HowItWorks';
import Pricing from './components/Pricing';
import Testimonials from './components/Testimonials';
import FAQ from './components/Faq';
import Footer from './components/Footer';
import { Element, Events, scrollSpy } from 'react-scroll';
import SEO from './components/SEO';
import AnalyticsTracker from './components/Analytics';

class App extends React.Component {
  componentDidMount() {
    Events.scrollEvent.register('begin', function() {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register('end', function() {
      console.log("end", arguments);
    });

    scrollSpy.update();
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  render() {
    return (
      <div className="app">
        <SEO />
        <AnalyticsTracker />
        <Header />
        <main>
          <Element name="home"><Hero /></Element>
          <Element name="features"><Features /></Element>
          <Element name="how-it-works"><HowItWorks /></Element>
          <Element name="pricing"><Pricing /></Element>
          <Element name="testimonials"><Testimonials /></Element>
          <Element name="faq"><FAQ /></Element>
        </main>
        <Footer />
      </div>
    );
  }
}

export default App;