import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = () => {
  const title = "Garnii | Solution complète de gestion pour votre Food Truck";
  const description = "Optimisez la gestion de votre food truck avec Garnii. Solution complète : commandes en temps réel, programme de fidélité, analytics. Essai gratuit.";
  const keywords = "food truck, gestion food truck, logiciel food truck, application food truck, commandes food truck, programme fidélité food truck";
  
  return (
    <Helmet>
      {/* Balises meta de base */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      
      {/* Balises Open Graph pour les réseaux sociaux */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content="https://garnii.fr" />
      <meta property="og:image" content="/images/garnii-og-image.jpg" />
      
      {/* Balises Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content="/images/garnii-og-image.jpg" />
      
      {/* Balises schema.org pour les rich snippets */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            "name": "Garnii",
            "applicationCategory": "BusinessApplication",
            "operatingSystem": "Android, iOS",
            "offers": {
              "@type": "Offer",
              "price": "39.00",
              "priceCurrency": "EUR"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.8",
              "reviewCount": "400"
            }
          }
        `}
      </script>
    </Helmet>
  );
};

export default SEO;