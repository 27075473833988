import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header
      className={`bg-white shadow-md fixed w-full z-10 transition-shadow duration-300 ease-in-out ${
        isScrolled ? 'shadow-lg' : 'shadow-none'
      }`}
    >
      <div className="container mx-auto px-6 py-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Link
              to="home"
              smooth={true}
              duration={500}
              className="font-semibold text-xl text-emerald-700 hover:text-emerald-700 transition duration-300 cursor-pointer"
            >
              Garnii
            </Link>
          </div>

          <button
            className="md:hidden focus:outline-none"
            onClick={toggleMenu}
            aria-label={isMenuOpen ? 'Fermer le menu' : 'Ouvrir le menu'}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>

          <nav className="hidden md:flex items-center space-x-4">
            <Link
              to="features"
              smooth={true}
              duration={500}
              className="text-gray-800 hover:text-emerald-700 transition duration-300 cursor-pointer"
            >
              Fonctionnalités
            </Link>
            <Link
              to="how-it-works"
              smooth={true}
              duration={500}
              className="text-gray-800 hover:text-emerald-700 transition duration-300 cursor-pointer"
            >
              Comment ça marche
            </Link>
            <Link
              to="pricing"
              smooth={true}
              duration={500}
              className="text-gray-800 hover:text-emerald-700 transition duration-300 cursor-pointer"
            >
              Tarifs
            </Link>
            <Link
              to="faq"
              smooth={true}
              duration={500}
              className="text-gray-800 hover:text-emerald-700 transition duration-300 cursor-pointer"
            >
              FAQ
            </Link>
          </nav>
        </div>
      </div>

      <div
        className={`md:hidden fixed inset-0 bg-white z-20 transform ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        } transition-transform duration-300 ease-in-out`}
      >
        <div className="flex justify-end p-4">
          <button
            onClick={toggleMenu}
            className="text-gray-800 hover:text-emerald-700 transition duration-300"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <nav className="flex flex-col items-center space-y-4 mt-8">
          <Link
            to="features"
            className="text-gray-800 hover:text-emerald-700 transition duration-300"
            onClick={toggleMenu}
          >
            Fonctionnalités
          </Link>
          <Link
            to="how-it-works"
            className="text-gray-800 hover:text-emerald-700 transition duration-300"
            onClick={toggleMenu}
          >
            Comment ça marche
          </Link>
          <Link
            to="pricing"
            className="text-gray-800 hover:text-emerald-700 transition duration-300"
            onClick={toggleMenu}
          >
            Tarifs
          </Link>
          <Link
            to="faq"
            className="text-gray-800 hover:text-emerald-700 transition duration-300"
            onClick={toggleMenu}
          >
            FAQ
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;